<template>
  <div class="flex flex-col gap-4 w-full">
    <c-card class="w-full flex flex-col p-5">
      <div class="w-full gap-5 mb-6 flex">
        <c-card class="flex justify-between gap-4 py-6 px-4">
          <c-card class="flex justify-center items-center p-2">
            <c-icon class-name="text-jet" icon-name="icon-union" size="s"  />
          </c-card>
          <div class="flex items-center gap-4 divide-x divide-romanSilver">
            <div class="flex flex-col">
              <span class="text-xs font-semibold uppercase text-jet">Pending Requests</span>
              <span class="text-lg font-extrabold text-carrotOrange">{{ pendingRequests.length }}</span>
            </div>
            <div class="flex flex-col pl-4">
              <span class="text-xs font-semibold uppercase text-jet">Approved Requests</span>
              <span class="text-lg font-extrabold text-mediumSeaGreen">{{ approvedRequests.length }}</span>
            </div>
            <div class="flex flex-col pl-4">
              <span class="text-xs font-semibold uppercase text-jet">Disapproved Requests</span>
              <span class="text-lg font-extrabold text-desire">{{ disapprovedRequests.length }}</span>
            </div>
            <div class="flex flex-col pl-4">
              <span class="text-xs font-semibold uppercase text-jet">Cancelled Requests</span>
              <span class="text-lg font-extrabold text-desire">{{ cancelledRequests.length }}</span>
            </div>
          </div>
        </c-card>
      </div>
      <template v-slot:footer>
        <CardFooter
            printcard
            reloadcard
            search-placeholder="Search by leave type or details"
            @reload="getLeaveBackRequests()"
            @actionModal="isOpenFilter = true"
            @sortType="$queryBuilder({ sort: $event }, getLeaveBackRequests)"
            @searchResult="$queryBuilder({ search: $event }, getLeaveBackRequests)"
        />
      </template>
    </c-card>

    <c-table
      :headers="headers"
      :items="leaveBackRequests"
      aria-label="leave back request table"
      :loading="isFetching"
      :has-number="false"
      :page-sync="true"
      :pagination-list="pagination"
      @page="$queryBuilder({ page: $event }, getLeaveBackRequests)"
      @itemsPerPage="$queryBuilder({ perPage: $event }, getLeaveBackRequests)"
      v-if="leaveBackRequests.length || isFetching"
    >
      <template v-slot:item="{ item }">
        <span v-if="item.leaveRequest" class="text-darkPurple text-sm font-normal">
          {{ item.data.leaveRequest.category && item.data.leaveRequest.category.name }}
        </span>
        <span v-if="item.requestReason" class="text-darkPurple text-sm font-normal">
          {{ item.data.requestReason }}
        </span>
        <div v-if="item.requestStartDate" class="flex flex-col justify-start gap-1">
          <div class="text-darkPurple text-sm font-semibold whitespace-nowrap">
            {{ $DATEFORMAT(new Date(item.data.requestStartDate), 'MMMM dd, yyyy') }} to<br />
            {{ $DATEFORMAT(new Date(item.data.requestEndDate), 'MMMM dd, yyyy') }}
          </div>
          <span class="text-carrotOrange text-sm">
            {{item.data.leaveRequest.daysRequested }} Day(s)
          </span>
        </div>
        <span v-if="item.daysRequested" class="text-darkPurple text-sm font-normal">
          {{ item.data.daysRequested }} Day(s)
        </span>
        <div v-if="item.approvalStatus" class="rounded-md p-2 text-sm w-28 text-center capitalize"
          :class="{
            'bg-green-100 text-mediumSeaGreen': item.data.approvalStatus === 'approved',
            'bg-yellow-100 text-carrotOrange': item.data.approvalStatus === 'pending',
            'bg-red-100 text-flame': item.data.approvalStatus === 'disapproved',
            'bg-red-100 text-flame text-sm': item.data.approvalStatus === 'cancelled',
            'bg-gray-100 text-romanSilver': item.data.approvalStatus === 'draft',
          }">
          {{ item.data.approvalStatus }}
        </div>

        <div v-if="item.id">
          <c-menu left @click.stop="">
            <template v-slot:title>
              <c-icon icon-name="more_icon" size="xs" />
            </template>
            <div class="flex flex-col w-48 h-18 p-2 justify-start items-start">
              <div class="w-full" @click="$refs.viewDaysBackRequestRef.onOpen({leaveInfo: item.data, employeeInfo})">
                <div class="hover:bg-ghostWhite w-full rounded-md">
                  <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                    <c-icon icon-name="icon-eye" class-name="text-darkPurple" size="xs" />
                    <span class="text-darkPurple text-sm">View Details</span>
                  </div>
                </div>
              </div>
              <template v-if="item.data.approvalStatus === 'draft'">
                <div class="w-full" @click="$refs.getLeaveBackRef.onOpen(item.data)">
                  <div class="hover:bg-ghostWhite w-full rounded-md">
                    <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                      <c-icon icon-name="edit" class-name="text-blueCrayola" size="xs" />
                      <span class="text-darkPurple text-sm">Edit</span>
                    </div>
                  </div>
                </div>
              </template>
              <div class="w-full" @click="$refs.approvalStatusRef.toggle(item.data.id)">
                <div class="hover:bg-ghostWhite w-full rounded-md">
                  <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                    <c-icon icon-name="icon-upgrade" class-name="text-blueCrayola" size="xs" />
                    <span class="text-darkPurple text-sm">Approval Status</span>
                  </div>
                </div>
              </div>
              <template v-if="item.data.approvalStatus === 'draft' || item.data.approvalStatus === 'pending'">
                <div v-feature-request class="w-full" @click="selectedItem = item.data, isOpenDeleteModal = true">
                  <div class="hover:bg-ghostWhite w-full rounded-md">
                    <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                      <c-icon icon-name="icon-unavailable" class-name="text-flame" size="xs" />
                      <span class="text-darkPurple text-sm">Cancel</span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </c-menu>
        </div>
      </template>
    </c-table>

    <div v-else class="flex flex-col justify-center items-center gap-0 px-10">
      <c-icon icon-name="paygrade_Illustration" size="" class-name="w-56 h-56" />
      <p class="font-normal text-base leading-5 text-jet text-center w-1/2">
        Days back requests can be found here. This is empty now,
        but you can start adding days back requests to populate this area.
      </p>
    </div>

    <FilterTable
      v-if="isOpenFilter"
      :filter-data="filterOptions"
      :show-search="false"
      @close="isOpenFilter = false"
      @applyFilter="onSubmitFilter($event)"
    />

    <c-modal v-if="isOpenDeleteModal">
      <c-card class="flex flex-col gap-5 max-w-max py-3 px-5">
        <p class="text-jet text-base font-bold border-b border-solid border-romanSilver">Attention Required!</p>
        <div class="flex flex-col justify-start items-start gap-0">
          <p class="text-jet text-base">Are you sure you want to delete this request?</p>
          <p class="text-jet text-base">This cannot be undone.</p>
        </div>
        <div class="w-full flex justify-center gap-4">
          <c-button
            label="Cancel"
            variant="secondary c-button text-dynamicBackBtn border border-solid border-dynamicBackBtn"
            :disabled="isDeletingRequest"
            @onClick="isOpenDeleteModal = false"
          />
          <c-button
            variant="primary btn-bg c-button"
            :disabled="isDeletingRequest"
            :label="isDeletingRequest ? 'Deleting...' : 'Delete'"
            @onClick="onCancelDaysBackRequest(selectedItem)"
          />
        </div>
      </c-card>
    </c-modal>

    <ViewDaysBackRequest ref="viewDaysBackRequestRef" />
    <GetLeaveBackRequest ref="getLeaveBackRef" @success="getLeaveBackRequests" />
    <ApprovalStatus ref="approvalStatusRef" />
  </div>
</template>

<script>
import { differenceInDays } from "date-fns"
import CTable from "@scelloo/cloudenly-ui/src/components/table"
import CCard from "@scelloo/cloudenly-ui/src/components/card"
import CButton from "@scelloo/cloudenly-ui/src/components/button"
import CMenu from "@scelloo/cloudenly-ui/src/components/Menu"
import CIcon from "@/components/Icon"
import CModal from "@/components/Modal"
import CardFooter from "@/components/CardFooter"
import FilterTable from "@/components/FilterTable"
import ApprovalStatus from "@/components/ApprovalStatus"

export default {
  components: {
    CCard,
    CTable,
    CIcon,
    CMenu,
    CModal,
    CButton,
    CardFooter,
    FilterTable,
    ApprovalStatus,

    ViewDaysBackRequest: () => import('./sideActions/ViewDaysBackRequest'),
    GetLeaveBackRequest: () => import('./sideActions/GetLeaveBack'),
  },
  data() {
    return {
      differenceInDays,

      isOpenDeleteModal: false,
      isOpenFilter: false,
      isFetching: false,
      isDeletingRequest: false,
      selectedItem: {},
      setQueryTimeout: null,
      queryParams: {},
      leaveBackRequests: [],
      employeeInfo: {},
      pendingRequests: [],
      approvedRequests: [],
      disapprovedRequests: [],
      cancelledRequests: [],
      pagination: {},
      headers: [
        { title: "Leave Type", value: "leaveRequest" },
        { title: "Details", value: "requestReason" },
        { title: "Approved Duration", value: "requestStartDate" },
        { title: "Days Back Request", value: "daysRequested" },
        { title: "status", value: "approvalStatus" },
        { title: "", value: "id", image: true },
      ],
      filterOptions: [
        {
          header: 'Status',
          value: 'status',
          optionsBox: [
            { name: 'Pending', id: 'pending' },
            { name: 'Approved', id: 'approved' },
            { name: 'Disapproved', id: 'disapproved' },
            { name: 'Cancelled', id: 'cancelled' },
          ]
        },
      ]
    };
  },
  methods: {
    onSubmitFilter(options){
      this.$queryBuilder(options, this.getLeaveBackRequests)
      this.isOpenFilter = false
    },
    onCancelDaysBackRequest(request) {
      this.isDeletingRequest = true
      this.$_cancelDaysBackRequest(request.id).then(({ data }) => {
        this.$toasted.success(data.message, {duration: 3000})
        this.getLeaveBackRequests()
        this.isDeletingRequest = false
        this.isOpenDeleteModal = false
      }).catch((error) => {
        this.$toasted.error(error.message, {duration: 3000})
        this.getLeaveBackRequests()
        this.isDeletingRequest = false
      });
    },
    async getLeaveBackRequests(params){
      this.isFetching = true

      const payload = { id: this.$AuthUser.id, params }
      await this.$_getEmployeeLeaveBackRequest(payload).then(({ data }) => {
        this.pagination = data.meta
        this.employeeInfo = data.employee
        this.leaveBackRequests = data.requests.map(v => ({ ...v, leaveBackId: v.id}))
        this.pendingRequests = data.requests?.filter(v => v.approvalStatus === 'pending')
        this.approvedRequests = data.requests?.filter(v => v.approvalStatus === 'approved')
        this.disapprovedRequests = data.requests?.filter(v => v.approvalStatus === 'disapproved')
        this.cancelledRequests = data.requests?.filter(v => v.approvalStatus === 'cancelled')

        this.isFetching = false
      })
    },
   async bootstrapModule(){
      await this.getLeaveBackRequests()
    },
  },
  created() {
    this.bootstrapModule()
  }
};
</script>

<style>
.userContainer {
  padding: 8px;
  border-bottom: 1px dashed #878e99;
}

input[type="radio"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: none;
  box-shadow: 0px 0px 0px 2px #eeeeee inset;
}

input[type="radio"]:checked {
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: none;
  background-color: #f15a29 !important;
  box-shadow: 0px 0px 0px 2px #eeeeee inset;
}

.c-button {
  width: 100% !important;
  padding: 10px 40px !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-bg {
    background-color: var(--dynamic-Background) !important;
 }
</style>
